import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useWindowSize } from "../../utils/useWindowSize";

import { Box } from "@mui/material";

import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import { HttpUnAuthApi } from "../../interface/unauth-api";

interface propsType {}

const ShortTest = (props: propsType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const queryParams = queryString.parse(location.search);

  const toastRef: any = useRef();
  const frameRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [ebdCode, setEbdCode] = useState("");

  const onMessageArrive = (e: MessageEvent) => {
    if (e.data.msg !== undefined) {
      // console.log("e.data : ", e.data);

      // 창닫기
      if (e.data.msg === "closeWindow") {
        // window.alert("창닫기 명령 도착");
        // window.postMessage({ msg: "closed window" }, "https://thd-play.livehyundai.com");
        // console.log("window.close()");
        navigate(-1);
      }

      // 상품클릭
      if (e.data.msg === "showProdDetail") {
        window.open(`https://www.thehyundai.com/front/pda/itemPtc.thd?slitmCd=${e.data.productId}`, "_blank");
      }

      // 더보기 클릭
      if (e.data.msg === "campagin") {
        window.open(e.data.url, "_blank");
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    window.addEventListener("message", onMessageArrive);

    return () => {
      setIsLoading(false);
      setEbdCode("");
      window.removeEventListener("message", onMessageArrive);
    };
  }, []);

  // Embed, Copy Link code generate
  const makeParam = () => {
    let ebdCode = `<iframe src="/short/${params.shortSeq}/${queryParams.id}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="test"></iframe>`;
    setEbdCode(ebdCode);
  };

  useEffect(() => {
    if (params.shortSeq !== undefined) makeParam();
  }, [params]);

  return (
    <>
      <Box ref={frameRef} dangerouslySetInnerHTML={{ __html: ebdCode }} />

      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default ShortTest;
