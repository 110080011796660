import { useEffect, useRef, useState } from "react";
import "context-filter-polyfill";
import { styled } from "@mui/material/styles";
import { Box, Typography, IconButton, Avatar, Drawer, Stack, Slider, CardMedia } from "@mui/material";

import PlayerControls from "./PlayerControls";

import usePlayer from "../../hooks/usePlayer";
import useMobileBreakpoint from "../../../contexts/MobileBreakpoint/useMobileBreakpoint";
import { isMobileOS } from "../../../utils";
import config from "../../../config";

import ShortProdList from "../../../page/short/ShortProdList";
import ShortLike from "../Like/ShortLike";
import { HttpUnAuthApi } from "../../../interface/unauth-api";
import { HttpStatisticApi } from "../../../interface/statistic-api";
// import IoTClient from "../../../utils/iot/IoTClient";
import { CommonUtils } from "../../../utils/common_utils";

import "./Player.css";
import ShortShare from "../../../page/short/ShortShare";

import { isBrowser, isMobile, browserName, fullBrowserVersion, mobileModel, osVersion, osName } from "react-device-detect";

const { PLAY_IN_BACKGROUND } = config;

const unauthApi = new HttpUnAuthApi();
const statisticApi = new HttpStatisticApi();
const utils = new CommonUtils();

const iOSBoxShadow = "none !important";

const VideoSlider = styled(Slider)(({ theme }) => ({
  color: "#00bc80",
  // height: 5,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 0,
    width: 0,
    backgroundColor: "#fff",
    boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
    "&:before": {
      boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
    },
    "&:after": {
      width: 0,
      height: 0,
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    transition: "width 0.21s linear",
    // height: 5,
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    boxShadow: "inset 0px 0px 4px -2px #000",
    backgroundColor: "#ffffff",
  },
}));

/**
 * Props:
 * @param {number} id   Player ID
 * @param {string} state 'ACTIVE' | 'NEXT' | 'PREV'
 * @param {string} playbackUrl stream URL to load into the player for playback
 * @param {object} swiper Swiper instance
 * @param {boolean} isPlayerActive true if the player is active (1 or 2 players could be active at the same time due to Swiper duplicates)
 * @param {boolean} isPlayerVisible true if the player is currently visible in the viewport (only 1 active player can be visible at any time)
 * @param {boolean} metadataVisible true if the metadata panel is expanded
 * @param {function toggleMetadata(): void} toggleMetadata toggles metadata panel in mobile view
 * @param {function gotoStream(dir: string): void} gotoStream sets the active stream to the one corresponding to dir ('next' or 'prev')
 */
const Player = ({
  id,
  state,
  playbackUrl,
  metadata,
  isPlayerActive,
  isPlayerVisible,
  gotoStream,
  globalMuted,
  objectFit,
  setObjectFit,
  viewControl,
  shortInfo,
  iotClientId,
  payloads,
  postMsg,
  toastMsg,
  setIsLoading,
  groupVdoNo,
  playlistLength,
}) => {
  const {
    pid,
    video,
    videoState,
    load,
    paused,
    loading,
    toggleMute,
    togglePlayPause,
    play,
    sliderPlay,
    pause,
    sliderPause,
    jumpTo,
    setVisibleState,
  } = usePlayer(id, isPlayerVisible, isPlayerActive, setIsLoading);
  const { isMobileView } = useMobileBreakpoint();
  const isActive = useRef(isPlayerActive);
  const isVisible = useRef(isPlayerVisible);

  const videoSliderRef = useRef();
  const thumbImgRef = useRef();

  // const [videoInfo, setVideoInfo] = useState({});
  const videoInfo = useRef({});
  const likeRef = useRef();
  const [prodList, setProdList] = useState([]);
  const [campaignInfo, setCampaignInfo] = useState({});

  const isChange = useRef(false); // playtime slider
  const videoDurationRef = useRef();

  const [videoCurrentTime, setVideoCurrentTime] = useState(0);

  const [sliderHeigth, setSliderHeight] = useState(4);
  const [sliderThumbLeft, setSliderThumbLeft] = useState("0%");
  const [sliderThumbImgRoot, setSliderThumbImgRoot] = useState("");
  const [sliderThumbImgUrl, setSliderThumbImgUrl] = useState("");
  const [sliderThumbImgUrls, setSliderThumbImgUrls] = useState([]);
  const [sliderThumbImgTime, setSliderThumbImgTime] = useState("");
  const [sliderThumbImgWidth, setSliderThumbImgWidth] = useState(0);

  const [thdVideoNo, setThdVideoNo] = useState("");
  const [maxResolution, setMaxResolution] = useState(540);

  const [thdProdInfo, setThdProdInfo] = useState({});
  const [thdVideoInfo, setThdVideoInfo] = useState({});

  useEffect(() => {
    return () => {
      setProdList([]);
      setCampaignInfo({});
      setVideoCurrentTime(0);
      setSliderHeight(4);
      setSliderThumbLeft("0%");
      setSliderThumbImgRoot("");
      setSliderThumbImgUrl("");
      setSliderThumbImgUrls([]);
      setSliderThumbImgTime("");
      setSliderThumbImgWidth(0);
      setThdVideoNo("");
      setMaxResolution(540);
      setThdProdInfo({});
      setThdVideoInfo({});
    };
  }, []);

  // IoT Event
  useEffect(() => {
    for (let i = 0; i < payloads.length; i++) {
      const payload = payloads[i];
      if (payload.video_key === metadata.sk) {
        if (payload.prot === "view") likeRef.current?.totalViewUp();
        if (payload.prot === "like") likeRef.current?.totalLikeUp();
        if (payload.prot === "like" && payload.senderId !== iotClientId) likeRef.current.newLikeUp();
        payloads.splice(i, 1);
      }
    }
  }, [payloads]); // eslint-disable-line react-hooks/exhaustive-deps

  // 더현대 상품 리스트 품절여부 체크
  const checkDisplay = (recieveProdList) => {
    let tempList = [];
    if (typeof recieveProdList === "object") {
      for (const prodInfo of recieveProdList) {
        if (prodInfo.display) {
          // 품절처리
          tempList = [...tempList, prodInfo];
        }
      }
    }

    return tempList;
  };

  // 더현대 상품 목록 수신
  useEffect(() => {
    if (thdProdInfo.prodList !== undefined) {
      const list = checkDisplay(thdProdInfo.prodList);
      if (thdProdInfo.video_key === metadata.sk) {
        // console.log("========================================");
        // console.log("상품리스트 수신 ");
        // console.log("- vishare video_key : ", metadata.sk);
        // console.log("- thd msg : ", thdProdInfo);
        // console.log("========================================");
        setProdList(list);
      }
    }
  }, [thdProdInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (thdVideoInfo.thdVideoNo !== undefined) {
      if (thdVideoInfo.video_key === metadata.sk) setThdVideoNo(thdVideoInfo.thdVideoNo);
    }
  }, [thdVideoInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  // 스와이퍼 change event
  useEffect(() => {
    isActive.current = isPlayerActive; // 현재 활성화
    isVisible.current = isPlayerVisible; // 현재 보이는 페이지

    isActive.current || PLAY_IN_BACKGROUND ? play() : pause();

    if (!isVisible.current) {
      toggleMute(globalMuted); // iOS는 비디오 테그가 사용자 클릭으로 권한을 모두 얻어야 함... 따라서 일단 권한 얻고 아래에서 음소거 처리
      setVisibleState(false);
      jumpTo(0); // 슬라이드 이동시 처음부터 재생하고 싶을때.
      setVideoCurrentTime((videoCurrentTime) => 0);
      setTimeout(() => {
        toggleMute(true);
      }, 200);
    } else {
      // 현재 보이는 페이지
      setVisibleState(true);
      toggleMute(globalMuted);
      // 시청자1 추가
      increaseStatistic(metadata.sk, "view");
      get_statistic_info(metadata.sk);
    }
  }, [isPlayerActive, isPlayerVisible, globalMuted]); // eslint-disable-line react-hooks/exhaustive-deps

  // 슬라이더 썸네일 이미지 경로 Root 계산
  const set_slider_thumbnail_root_path = (info) => {
    let rootPath = "";
    const playback = info.playback_url;
    let temp = playback.split("HLS");
    rootPath = temp[0] + "VideoClip";
    const temp2 = temp[1].split(".m3u8")[0];
    rootPath = rootPath + temp2;
    setSliderThumbImgRoot(rootPath);

    return rootPath;
  };

  // 슬라이더 썸네일 이미지 프리 로딩
  const set_slider_thumb_image_loading = (_duration, _fileRoot) => {
    let urls = [];
    for (let i = 0; i < _duration; i++) {
      const time = i.toString().padStart(7, "0");
      const url = `${_fileRoot}.${time}.jpg`;

      urls = [...urls, url];
    }
    setSliderThumbImgUrls(urls);
  };

  // 비디오 상세정보 조회
  const get_video_info = async (videoKey) => {
    const param = {
      command: "get_video_info",
      video_key: videoKey,
    };

    const res = await unauthApi.post(param);
    // console.log("res : ", res);
    if (res.code === "200") {
      if (res.response.video_info.video_duration !== undefined) {
        if (typeof res.response.video_info.video_duration === "string") {
          videoDurationRef.current = parseFloat(res.response.video_info.video_duration);
        } else {
          videoDurationRef.current = res.response.video_info.video_duration;
        }
      }
      // setVideoInfo(res.response.video_info);
      videoInfo.current = res.response.video_info;
      // console.log("res.response.video_info.prod_list : ", res.response.video_info.prod_list);
      // const list = checkDisplay(res.response.video_info.prod_list);
      // console.log("list : ", list);
      // setProdList(list);
      postMsg({ msg: "getVideoNo", video_key: res.response.video_info.sk }, "*");
      // 실시간 더현대 상품 목록 조회
      postMsg({ msg: "getProdList", video_key: res.response.video_info.sk }, "*");

      setCampaignInfo(res.response.video_info.campaign_info);
      setMaxResolution(res.response.video_info.resolution);
      const rootPath = set_slider_thumbnail_root_path(res.response.video_info);
      set_slider_thumb_image_loading(videoDurationRef.current, rootPath);
    }
  };

  // 통계정보 조회
  const get_statistic_info = async (videoKey) => {
    const param = {
      command: "get_statistic_info",
      video_key: videoKey,
    };

    const res = await statisticApi.post(param);
    if (res.code === "200") {
      if (res.response.statistic_info?.total_views !== undefined) likeRef.current?.setTotalViewCnt(res.response.statistic_info.total_views);
      if (res.response.statistic_info?.total_likes !== undefined) likeRef.current?.setTotalLikeCnt(res.response.statistic_info.total_likes);
    }
  };

  // 상세정보 로딩 이벤트 (화면이 보이지 않는 마지막 레이어를 미리 로딩한다.)
  useEffect(() => {
    if (playbackUrl) {
      let max = 540;
      // console.log("maxResolution : ", maxResolution);
      if (maxResolution < 540) {
        max = maxResolution;
      }
      const newPlaybackUrls = playbackUrl.split(".m3u8");
      const newPlayBackUrl = newPlaybackUrls[0] + "_" + max + ".m3u8";
      // console.log("newPlayBackUrl : ", newPlayBackUrl);
      load(newPlayBackUrl);
      get_video_info(metadata.sk);
      get_statistic_info(metadata.sk);
    }
  }, [playbackUrl, metadata, maxResolution]); // eslint-disable-line react-hooks/exhaustive-deps

  // statistic count
  const increaseStatistic = async (videoKey, prot) => {
    const param = {
      command: "update_short_count",
      short_key: shortInfo.sk,
      video_key: videoKey,
      prot: prot,
      client_id: iotClientId,
    };

    await statisticApi.post(param);
  };

  // 비디오 클릭시 재생/일시정지 토글
  const handleClickOnPlayer = (e) => {
    togglePlayPause();
  };

  // 슬라이더 Thumb 위치에 따라서 썸네일 이미지 div 위치 계산
  const calcSliderThumbImgPos = (pos) => {
    const dur = videoDurationRef.current;
    const div = 100 * (pos / dur);
    const imageWidth = (160 / parseFloat(videoInfo.current.video_rate.split("%")[0])) * 100;
    setSliderThumbImgWidth(imageWidth); // 슬라이더 이미지 넓이 계산

    let leftPos = "";
    if (div < 50) {
      leftPos = `max(0px, calc(${div}% - ${imageWidth / 2}px))`;
    } else {
      leftPos = `min(calc(100% - ${imageWidth}px), calc(${div}% - ${imageWidth / 2}px))`;
    }
    setSliderThumbLeft((sliderThumbLeft) => leftPos);
  };

  // 슬라이더 재생시간, 해당 시간의 이미지 경로 설정
  const set_slider_thumb_image_layer = (sec) => {
    const time = Math.floor(sec).toString().padStart(7, "0");
    setSliderThumbImgTime(valueLabelFormat(sec));
    const url = `${sliderThumbImgRoot}.${time}.jpg`;
    setSliderThumbImgUrl(url);
  };

  // 비디오 탐색 슬라이더 변경시 이벤트
  const handleChange = (event, newValue) => {
    isChange.current = true;
    if (isActive.current) {
      set_slider_thumb_image_layer(newValue);
      calcSliderThumbImgPos(newValue);

      setVideoCurrentTime((videoCurrentTime) => newValue);
      setSliderHeight(10);
    }
  };

  // 비디오 탐색 슬라이더 변경 완료시 이벤트
  const completeChange = (event, newValue) => {
    if (isActive.current) {
      jumpTo(newValue);
      setSliderHeight(4);
    }

    isChange.current = false;
  };

  // 탐색 슬라이더 현재 플레이 타임 표시
  const setPlayTime = () => {
    if (isActive.current && !isChange.current && videoState.current !== "Ended") {
      // 비디오 총시간(videoInfo에 video_druation이 없을 때만 동작)
      if (videoDurationRef.current === undefined) {
        videoDurationRef.current = video.current?.duration;
      }
      // console.log("video.current.currentTime : ", video.current.currentTime);
      setVideoCurrentTime((videoCurrentTime) => video.current.currentTime);
    }
  };

  // 현재 재생시간 포멧
  const valueLabelFormat = (value) => {
    const t = utils.convertSecToTime(value);
    if (videoDurationRef.current > 60 * 60) return t.hours + ":" + t.minutes + ":" + t.seconds;
    else return t.minutes + ":" + t.seconds;
  };

  // 비디오 이벤트 리스너
  useEffect(() => {
    video.current?.addEventListener("timeupdate", setPlayTime);

    return () => {
      if (video.current) video.current.removeEventListener("timeupdate", setPlayTime);
    };
  }, []);

  // 비디오 objectFit 변경 토글(임시 기능)
  const toggleObjectFit = () => {
    if (objectFit === "full") setObjectFit("center");
    else setObjectFit("full");
  };

  const onMessageArrive = (e) => {
    if (e.data.msg !== undefined) {
      // console.log("e.data : ", e.data);
      // 상품정보 최신화
      if (e.data.msg === "prodList") {
        setThdProdInfo({ video_key: e.data.video_key, prodList: e.data.prod_list });
      } else if (e.data.msg === "getVideoNo") {
        setThdVideoInfo({ video_key: e.data.video_key, thdVideoNo: e.data.videoNo });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", onMessageArrive);
    return () => {
      window.removeEventListener("message", onMessageArrive);
    };
  }, []);

  return (
    <div className="player-container" id={`${state.toLowerCase()}-player-${pid}`} inert={!isVisible.current && !isMobileOS() ? "" : null}>
      <div className={`player-video`}>
        <video
          id={`${state.toLowerCase()}-video`}
          ref={video}
          poster={osName === "iOS" ? "" : sliderThumbImgUrls.length > 0 ? sliderThumbImgUrls[0] : "/images/ready_img.png"}
          playsInline
          muted
          loop
          preload="metadata"
          onClick={handleClickOnPlayer}
          className={objectFit === "full" ? "video-full" : "video-center"}
        />
        {isActive.current && paused && !isChange.current && (
          <Box className="btn-play">
            <IconButton sx={{ padding: 0 }} onClick={handleClickOnPlayer}>
              <Box component="img" src="/images/btn-play.png" sx={{ width: "5rem" }} />
            </IconButton>
          </Box>
        )}
      </div>
      {isActive.current && (
        <>
          {videoInfo.current.tel_number !== undefined && videoInfo.current.tel_number !== "" && videoInfo.current.tel_number !== null && (
            <div className="short-player-call">
              <Box sx={{ pt: "2rem" }}>
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => {
                    document.location.href = `tel:${videoInfo.current.tel_number}`;
                  }}
                >
                  <Box component="img" src="/images/icon-call.png" sx={{ width: "25px", height: "25px" }} />
                </IconButton>
              </Box>
            </div>
          )}
          <div className="short-player-share">
            <ShortShare
              videoKey={videoInfo.current.sk !== undefined && videoInfo.current.sk !== null ? videoInfo.current.sk : ""}
              shortTitle={
                videoInfo.current.display_title !== undefined && videoInfo.current.display_title !== null
                  ? videoInfo.current.display_title
                  : ""
              }
              shortSubTitle={
                videoInfo.current.display_sub_title !== undefined && videoInfo.current.display_sub_title !== null
                  ? videoInfo.current.display_sub_title
                  : ""
              }
              coverImage={
                videoInfo.current.cover_image_url !== undefined && videoInfo.current.cover_image_url !== null
                  ? videoInfo.current.cover_image_url
                  : ""
              }
              groupVdoNo={groupVdoNo}
              thdVideoNo={thdVideoNo}
              toastMsg={toastMsg}
              postMsg={postMsg}
            />
          </div>
          <div className="short-player-title">
            {/* 카테고리명 */}
            <Box>
              <Stack sx={{ alignItems: "center" }} direction={"row"} spacing={1}>
                <Box>
                  <Avatar sx={{ backgroundColor: "transparent", width: "30px", height: "30px" }} src={shortInfo.thumbImageUrl}>
                    {""}
                  </Avatar>
                </Box>
                <Box>
                  <Typography className="short-category-title" onClick={toggleObjectFit}>
                    {shortInfo.title}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            {/* 메인타이틀 */}
            {videoInfo.current.display_title !== undefined &&
              videoInfo.current.display_title !== null &&
              videoInfo.current.display_title !== "" && (
                <Box sx={{ mt: 1.4 }}>
                  <Typography className="short-title-text">{videoInfo.current.display_title}</Typography>
                </Box>
              )}
            {/* 서브타이틀 */}
            {videoInfo.current.display_sub_title !== undefined &&
              videoInfo.current.display_sub_title !== null &&
              videoInfo.current.display_sub_title !== "" && (
                <Box
                  sx={
                    videoInfo.current.display_sub_title !== undefined &&
                    videoInfo.current.display_sub_title !== null &&
                    videoInfo.current.display_sub_title !== ""
                      ? { mt: 1 }
                      : { mt: 1.4 }
                  }
                >
                  <Typography className="short-desc-text">{videoInfo.current.display_sub_title}</Typography>
                </Box>
              )}
          </div>
          <div className="short-player-right-bottom">
            {/* 좋아요 */}

            <ShortLike
              ref={likeRef}
              like_click={() => {
                increaseStatistic(metadata.sk, "like");
              }}
            />

            <ShortProdList prodList={prodList} metadata={videoInfo.current} campaignInfo={campaignInfo} postMsg={postMsg} />
          </div>
          {/* 슬라이더 */}
          <div className="short-player-slider">
            <Box sx={{ width: "100%", position: "relative" }}>
              <VideoSlider
                aria-label="video"
                ref={videoSliderRef}
                value={videoCurrentTime}
                min={0}
                max={videoDurationRef.current}
                step={0.001}
                onChange={handleChange}
                onChangeCommitted={completeChange}
                sx={{
                  height: sliderHeigth,
                  transition: "height 0.2s ease-in",
                  "& .Mui-focusVisible": { boxShadow: "none !important" },
                }}
              />
              {isChange.current && (
                <Box ref={thumbImgRef} sx={{ position: "absolute", left: sliderThumbLeft, bottom: "85px", maxLeft: "0%" }}>
                  <Box sx={{ position: "relative", maxWidth: `${sliderThumbImgWidth}px` }}>
                    <CardMedia
                      component="img"
                      image={sliderThumbImgUrl}
                      sx={{ objectFit: "contain", maxWidth: `${sliderThumbImgWidth}px`, maxHeight: "160px", borderRadius: "7px" }}
                    />
                    <Box sx={{ position: "absolute", left: "50%", bottom: "4px", transform: "translate(-50%, 0)", zIndex: "901" }}>
                      <Typography sx={{ color: "#ffffff" }}>{sliderThumbImgTime}</Typography>
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        right: 0,
                        top: 0,
                        borderRadius: "7px",
                        backgroundColor: "#100f0f",
                        opacity: "0.2",
                        zIndex: "900",
                      }}
                    ></Box>
                  </Box>
                </Box>
              )}
            </Box>
          </div>
        </>
      )}
      <PlayerControls gotoStream={gotoStream} isPlayerVisible={isPlayerVisible} hidden={(isMobile && !viewControl) || playlistLength < 2} />
      {sliderThumbImgUrls.map((url, index) => (
        <CardMedia key={`temp-${index}`} component="img" image={url} sx={{ height: 0, width: 0 }} />
      ))}
    </div>
  );
};

export default Player;
