import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import S3UploaderViaApiForCreatVideo from "../../utils/attach/S3UploaderViaApiForCreatVideo";

interface propsType {}

const EbdUploadVideo = (props: propsType) => {
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const [txtBtn, setTxtBtn] = useState("동영상 선택");

  const [attachFiles, setAttachFiles] = useState<any>([]);
  const [videoKey, setVideoKey] = useState("");

  useEffect(() => {
    return () => {
      setTxtBtn("동영상 선택");
      setAttachFiles([]);
      setVideoKey("");
    };
  }, []);

  useEffect(() => {
    setTxtBtn(queryParams.txtBtn !== undefined ? queryParams.txtBtn : "동영상 선택");
    setVideoKey(queryParams.videoKey !== undefined ? queryParams.videoKey : "");
  }, [queryParams]);

  const close = () => {
    setAttachFiles([]);
  };

  // 첨부파일 업로드 완료시 콜백
  const fncUploadComplete = async (result: any) => {
    if (result.status === "ok") {
      window.parent.postMessage({ msg: "upload_finish", video_key: result.fileInfo[0]?.FILE_KEY }, "*");
      close();
    } else {
      console.error("vishare upload button error : 업로드에 실패하였습니다. 관리자에게 문의하세요.");
    }
  };

  return (
    <>
      {attachFiles.length === 0 && (
        <S3UploaderViaApiForCreatVideo
          userState={{ id: queryParams.id, name: "", company: "", isAuth: false, isAdmin: false, isSuperAdmin: false, apiUrl: "" }}
          multiple={false}
          callBack={fncUploadComplete}
          setIsLoading={() => {}}
          borderRadius=""
          width="100%"
          variant="contained"
          buttonText={txtBtn}
          dragDrop={false}
          videoKey={videoKey}
        />
      )}
    </>
  );
};

export default EbdUploadVideo;
