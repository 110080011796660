/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
const awsmobile = {
  aws_project_region: "ap-northeast-2",

  Auth: {
    region: "ap-northeast-2",
    userPoolId: "ap-northeast-2_wxgwVy8LL", // Please change this value.
    userPoolWebClientId: "68s1bht8lele9f3n2s3v8b7lpe", // Please change this value.
    identityPoolId: "ap-northeast-2:46660118-a07f-4deb-907b-e49e065b6873", // Please change this value.
  },
  aws_cloud_logic_custom: [
    {
      name: "MainApi",
      endpoint: "https://ppgkgs72d1.execute-api.ap-northeast-2.amazonaws.com/Prod",
      region: "ap-northeast-2",
    },
    {
      name: "UnAuth",
      endpoint: "https://ppgkgs72d1.execute-api.ap-northeast-2.amazonaws.com/Prod",
      region: "ap-northeast-2",
    },
    {
      name: "Statistic",
      endpoint: "https://ppgkgs72d1.execute-api.ap-northeast-2.amazonaws.com/Prod",
      region: "ap-northeast-2",
    },
  ],
};

export default awsmobile;
