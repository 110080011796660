import dayjs from "dayjs";
import * as XLSX from "xlsx";

export class CommonUtils {
  public dateFormat = (value: string, iPattern: string, oPattern: string) => {
    return dayjs(value, iPattern).format(oPattern);
  };

  public convertSecToTime = (timeInSeconds: number) => {
    let hours: any = Math.floor(timeInSeconds / 3600);
    let minutes: any = Math.floor((timeInSeconds - hours * 3600) / 60);
    let seconds: any = Math.floor(timeInSeconds - hours * 3600 - minutes * 60);

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (hours > 99) {
      hours = this.numericComma(Number(hours));
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return {
      hours: "" + hours,
      minutes: "" + minutes,
      seconds: "" + seconds,
    };
  };

  public isEmptyObj(obj: any) {
    if (obj === null || obj === undefined) return true;
    if (obj.constructor === Object && Object.keys(obj).length > 0) {
      return false;
    }

    return true;
  }

  public numericComma = (value: any) => {
    if (typeof value === "number") {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else if (typeof value === "string") return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return undefined;
  };

  public downloadExcel = (sheetContent: any, colWidth: any, sheetName: string, fileName: string) => {
    const sheet: any = XLSX.utils.aoa_to_sheet(sheetContent);
    sheet["!cols"] = colWidth;
    const chatFile = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(chatFile, sheet, sheetName);
    XLSX.writeFile(chatFile, fileName);
  };

  public getRandomNum = (min: number, max: number) => {
    let num = Math.floor(Math.random() * max + 1) + min;
    return num;
  };
}
