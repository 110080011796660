import { useEffect, useState, useCallback, useRef } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import { isBrowser, osName } from "react-device-detect";

import "./ShortShare.css";
import CopyToClipboard from "react-copy-to-clipboard";

interface propsType {
  videoKey: string;
  shortTitle: string;
  shortSubTitle: string;
  coverImage: string;
  groupVdoNo: string;
  thdVideoNo: string;
  toastMsg: any;
  postMsg: any;
}

declare const window: any;
const ShortShare = (props: propsType) => {
  if (!window.Kakao.isInitialized()) {
    window.Kakao.init(process.env.REACT_APP_KAKAO_KEY);
  }
  const [openShare, setOpenShare] = useState(false);

  const makeUrl = () => {
    return `https://m.thehyundai.com/front/cov/shortsDetail.thd?groupVdoNo=${props.groupVdoNo}&videoNo=${props.thdVideoNo}`;
  };

  useEffect(() => {
    return () => {
      setOpenShare(false);
    };
  }, []);

  const handleShareModalClose = () => {
    setOpenShare(false);
  };

  const fncShareSms = () => {
    postMsg({ msg: "getVideoNo", video_key: props.videoKey });
    setTimeout(() => {
      postMsg({ msg: "sms", smsUrl: makeUrl() });
    }, 300);
  };

  // 부모창에 메세지 보내기
  const postMsg = (callback: any) => {
    if (callback !== undefined && callback !== "") {
      setTimeout(() => {
        props.postMsg(callback, "*");
      }, 200);
    }
  };

  const checkMobile = () => {
    let userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf("android") > -1) {
      return "android";
    } else if (userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1 || userAgent.indexOf("ipod") > -1) {
      return "ios";
    } else {
      return "other";
    }
  };

  const fncShareKakao = () => {
    window.Kakao.Share.sendDefault({
      objectType: "feed",
      content: {
        title: props.shortTitle,
        description: props.shortSubTitle,
        imageUrl: props.coverImage,
        link: {
          mobileWebUrl: makeUrl(),
          webUrl: makeUrl(),
        },
      },
      buttons: [
        {
          title: "숏폼 보러가기",
          link: {
            mobileWebUrl: makeUrl(),
            webUrl: makeUrl(),
          },
        },
      ],
    });
  };

  return (
    <>
      <Box
        className="player-share-btn"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: "17.5px",
          mt: "15px",
        }}
      >
        <Box sx={{ p: 0 }}>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => {
              setOpenShare(true);
            }}
          >
            <Box component="img" src="/images/icon-share.png" sx={{ width: "22px", height: "22px" }} />
          </IconButton>
        </Box>
      </Box>

      <Drawer
        PaperProps={{
          elevation: 0,
          style: {
            borderTopRightRadius: "20px",
            borderTopLeftRadius: "20px",
          },
          sx: {
            ...{
              width: "100%",
              left: "50%",
              transform: "translateX(calc(-50.4% + 0.5px)) !important",
              overflow: "hidden",
            },
          },
        }}
        anchor="bottom"
        open={openShare}
        onClose={handleShareModalClose}
      >
        <Box className="thd-share-more-body-box">
          <Box className="thd-share-top-header">
            <IconButton sx={{ padding: 0 }} onClick={handleShareModalClose} disableRipple>
              <CardMedia component="img" image={"/images/popup_icon_dropdown.png"} sx={{ height: "30px", objectFit: "contain" }} />
            </IconButton>
          </Box>
          <Box className="thd-share-header">
            <Box className="thd-share-header-title">공유하기</Box>
          </Box>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Stack className="thd-share-row" direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                onClick={fncShareKakao}
                sx={{ cursor: `${isBrowser ? "pointer" : ""}` }}
              >
                <Avatar sx={{ width: "72px", height: "72px" }} alt="Rem Sharp" src="/images/icon-kakao.png" />
                <Typography id="modal-modal-title" variant="caption">
                  카카오톡
                </Typography>
              </Stack>
              <Stack
                 direction="column"
                 justifyContent="center"
                 alignItems="center"
                 spacing={1}
                 onClick={fncShareSms}
                 sx={{ cursor: `${isBrowser ? "pointer" : ""}` }}
               >
                 <Avatar sx={{ width: "72px", height: "72px" }} alt="Rem Sharp" src="/images/icon-sms.png" />
                 <Typography id="modal-modal-title" variant="caption">
                   SMS
                 </Typography>
              </Stack>
              <CopyToClipboard
                text={`https://m.thehyundai.com/front/cov/shortsDetail.thd?groupVdoNo=${props.groupVdoNo}&videoNo=${props.thdVideoNo}`}
                onCopy={() => {
                  props.toastMsg("클립보드에 복사가 완료되었습니다.", "success", 3000, {
                    vertical: "top",
                    horizontal: "center",
                  });
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  onClick={() => {}}
                  sx={{ cursor: `${isBrowser ? "pointer" : ""}` }}
                >
                  <Avatar sx={{ width: "72px", height: "72px" }} alt="Rem Sharp" src="/images/icon-linkcopy.png" />
                  <Typography id="modal-modal-title" variant="caption">
                    URL 복사
                  </Typography>
                </Stack>
              </CopyToClipboard>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default ShortShare;
