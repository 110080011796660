import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

interface propsType {
  loading: boolean;
}

const LoadingCircleNoBackdrop = (props: propsType) => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          ...(props.loading ? { display: "flex" } : { display: "none" }),
        }}
      >
        <Stack direction="column">
          <div className="load-cont">
            <div className="spinner-wrap">
              <div className="spinner">
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          </div>
        </Stack>
      </Box>
    </>
  );
};

export default LoadingCircleNoBackdrop;
