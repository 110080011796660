import { Routes, Route, Navigate } from "react-router-dom";

import MainPage from "../page/mainPage/mainPage";
import Player from "../page/player/Player";
import Short from "../page/short/Short";
import ShortTest from "../page/short/ShortTest";
import EbdUploadVideo from "../page/ebdBtn/EbdUploadVideo";
import PlayerTest from "../page/player/PlayerTest";

const MainConteiner = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/video/:videoSeq" element={<Player />} />
      <Route path="/short/:shortSeq/:id" element={<Short />} />
      <Route path="/playerTest/:videoSeq" element={<PlayerTest />} />
      <Route path="/shortTest/:shortSeq/:id" element={<ShortTest />} />
      <Route path="/ebdUploadVideo" element={<EbdUploadVideo />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default MainConteiner;
