import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";

import { CommonUtils } from "../../../utils/common_utils";
import TheHyundaiEmoji from "./TheHyundai/TheHyundaiEmoji";
import { Typography } from "@mui/material";

interface propsType {
  like_click: any;
}

const ShortLike = (props: propsType, ref: any) => {
  const cUtils = new CommonUtils();
  const [thdw, setThdw] = useState([] as any);
  const [thdb, setThdb] = useState([] as any);
  const [hp, setHp] = useState([] as any);
  const [hw, setHw] = useState([] as any);
  const [cy, setCy] = useState([] as any);
  const [cp, setCp] = useState([] as any);

  const [totalView, setTotalView] = useState(0);
  const [totalLike, setTotalLike] = useState(0);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setThdw([] as any);
      setThdb([] as any);
      setHp([] as any);
      setHw([] as any);
      setCy([] as any);
      setCp([] as any);
    };
  }, []);

  const btnLikeClickCallback = () => {
    props.like_click();
    like_up();
  };

  const arryMax: number = 4;
  const like_up = () => {
    const max = 1;
    const target = cUtils.getRandomNum(0, 6);
    const makefunc = (arr: any, setArr: any) => {
      for (let i = 0; i < max; i++) {
        if (arr.length < arryMax) {
          setArr((ele: any) => [
            ...ele,
            {
              id: Date.now() + "/" + i,
            },
          ]);
        }
      }
    };
    if (target === 1) {
      makefunc(thdw, setThdw);
    } else if (target === 2) {
      makefunc(thdb, setThdb);
    } else if (target === 3) {
      makefunc(hp, setHp);
    } else if (target === 4) {
      makefunc(hw, setHw);
    } else if (target === 5) {
      makefunc(cy, setCy);
    } else {
      makefunc(cp, setCp);
    }
  };

  const totalLikeUp = () => {
    setTotalLike((totalLike) => totalLike + 1);
  };
  const totalViewUp = () => {
    setTotalView((totalView) => totalView + 1);
  };
  const newLikeUp = () => {
    like_up();
  };
  const setTotalLikeCnt = (cnt: number) => {
    setTotalLike(cnt);
  };
  const setTotalViewCnt = (cnt: number) => {
    setTotalView(cnt);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    totalLikeUp,
    totalViewUp,
    newLikeUp,
    setTotalLikeCnt,
    setTotalViewCnt,
  }));

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: "17.5px", mb: "18px" }}>
        <Stack direction="column" spacing={0}>
          <div className="heart_root">
            <div id="divheartActions" className="heart-actions no-scroll">
              <div className="like-wrapper">
                {thdw.map((item: any, idx: any) => (
                  <TheHyundaiEmoji key={item.id} emoji={thdw} setEmoji={setThdw} emojiCategory="thehd-wht" />
                ))}
                {thdb.map((item: any, idx: any) => (
                  <TheHyundaiEmoji key={item.id} emoji={thdb} setEmoji={setThdb} emojiCategory="thehd-blk" />
                ))}
                {hp.map((item: any, idx: any) => (
                  <TheHyundaiEmoji key={item.id} emoji={hp} setEmoji={setHp} emojiCategory="heart-pk" />
                ))}
                {hw.map((item: any, idx: any) => (
                  <TheHyundaiEmoji key={item.id} emoji={hw} setEmoji={setHw} emojiCategory="heart-wht" />
                ))}
                {cy.map((item: any, idx: any) => (
                  <TheHyundaiEmoji key={item.id} emoji={cy} setEmoji={setCy} emojiCategory="candy-yg" />
                ))}
                {cp.map((item: any, idx: any) => (
                  <TheHyundaiEmoji key={item.id} emoji={cp} setEmoji={setCp} emojiCategory="candy-pp" />
                ))}
              </div>
            </div>
          </div>
          <Box sx={{ position: "relative" }}>
            <IconButton sx={{ p: 0 }} onClick={btnLikeClickCallback}>
              <Box component="img" src="/images/icon-like.png" sx={{ width: "2rem" }} />
            </IconButton>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(calc(-50.4% + 0.5px)) !important",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ color: "#ffffff", fontSize: "14px" }}>{cUtils.numericComma(totalLike)}</Typography>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default forwardRef(ShortLike);
