import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsExports from "./config/aws-exports";
import "./App.css";
import MobileBreakpointProvider from "./contexts/MobileBreakpoint/provider";
import StreamProvider from "./contexts/Stream/provider";
import MainConteiner from "./mainContainer/MainConteiner";
import "@fontsource/noto-sans-kr";
Amplify.configure(awsExports);

const theme = createTheme({
  typography: {
    fontFamily: "Noto Sans KR",
  },
  palette: {
    // primary: {
    //   main: "#527FED",
    //   contrastText: "#fff",
    // },
    error: {
      main: "#ff0a00",
      contrastText: "#fff",
    },
  },
  zIndex: { drawer: 990, modal: 990 },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <MobileBreakpointProvider>
            <StreamProvider>
              <MainConteiner />
            </StreamProvider>
          </MobileBreakpointProvider>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
