import { ApiBase } from "./api-base";

export class HttpUnAuthApi extends ApiBase {
  onApiName(): string {
    return "UnAuth";
  }

  onUrlPath(): string {
    return "/unAuth";
  }

  public async create_user(param: any) {
    let requestBody: any = param;
    requestBody.command = "create_user";
    const response = await this.requestPost(requestBody);
    return response;
  }

  public async delete_user(param: any) {
    let requestBody: any = param;
    requestBody.command = "delete_user";
    const response = await this.requestPost(requestBody);
    return response;
  }

  public async post(param: any) {
    const response = await this.requestPost(param);
    return response;
  }
}
