import React, { useEffect, useRef } from "react";

import Button from "../../../common/Button";
import Like from "../../Like";

import config from "../../../../config";

import "./PlayerControls.css";

const PlayerControls = ({ hidden, gotoStream, isPlayerVisible }) => {
  const nextEl = useRef();
  const prevEl = useRef();

  useEffect(() => {
    if (isPlayerVisible) {
      const activeElemId = document.activeElement.id;
      setTimeout(() => {
        if (activeElemId === "next-stream") nextEl.current.focus();
        if (activeElemId === "prev-stream") prevEl.current.focus();
      }, config.SWIPE_DURATION);
    }
  }, [isPlayerVisible]);

  return (
    !hidden && (
      <div className="player-buttons">
        <Button ref={prevEl} id="prev-stream" onClick={() => gotoStream("prev")}>
          ChevronUp
        </Button>
        <Button ref={nextEl} id="next-stream" onClick={() => gotoStream("next")}>
          ChevronDown
        </Button>
      </div>
    )
  );
};

export default PlayerControls;
