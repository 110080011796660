import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useWindowSize } from "../../utils/useWindowSize";

import { Box, Button } from "@mui/material";

import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import { HttpUnAuthApi } from "../../interface/unauth-api";

interface propsType {}

const PlayerTest = (props: propsType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const queryParams = queryString.parse(location.search);

  const toastRef: any = useRef();
  const frameRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [ebdCode, setEbdCode] = useState("");

  const onMessageArrive = (e: MessageEvent) => {
    // if (e.data.msg !== undefined && e.data.videoKey !== undefined) {
    //   // Player Play
    //   if (e.data.msg === "play") {
    //     if(params.videoSeq !== undefined && params.videoSeq !== "" && e.data.videoKey === params.videoSeq) {
    //       if (videoUrl !== undefined && videoUrl !== "") {
    //         player.play();
    //         setPlay(true);
    //       } else {
    //         videoRef.current?.play();
    //         setPlay(true);
    //       }
    //     }
    //   }
    //   // Player Stop
    //   if (e.data.msg === "stop") {
    //     if(videoSeq !== undefined && videoSeq !== "" && e.data.videoKey === videoSeq) {
    //       if (videoUrl !== undefined && videoUrl !== "") {
    //         player.pause();
    //         setPlay(false);
    //       } else {
    //         videoRef.current?.pause();
    //         setPlay(false);
    //       }
    //     }
    //   }
    // }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    // window.addEventListener("message", onMessageArrive);

    return () => {
      setIsLoading(false);
      setEbdCode("");
      // window.removeEventListener("message", onMessageArrive);
    };
  }, []);

  // Embed, Copy Link code generate
  const makeParam = () => {
    let ebdCode = `<div style='padding:177.78% 0 0 0;position:relative;'><iframe src='https://thd-play.livehyundai.com/video/${params.videoSeq}?noTitle&noControl&noLike' frameborder='0' allow='autoplay; fullscreen; picture-in-picture' style='position:absolute;top:0;left:0;width:100%;height:100%;' title='더현대숏폼1.mp4'></iframe></div>`
    setEbdCode(ebdCode);
  };

  const sendPlayPostMessage = () => {
    frameRef.current.contentWindow.postMessage({ msg: "play", videoKey: params.videoSeq }, "*");
  }

  const sendStopPostMessage = () => {
    console.log("stop called")
    frameRef.current.contentWindow.postMessage({ msg: "stop", videoKey: params.videoSeq }, "*");
  }

  useEffect(() => {
    if (params.videoSeq !== undefined) makeParam();
  }, [params]);

  return (
    <>
      <Box>
        <Button
          variant="contained"
          onClick={() => {
            sendPlayPostMessage();
          }}
        >
          Send Play
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            sendStopPostMessage();
          }}
        >
          Send Stop
        </Button>
      </Box>
      
      {/* <Box ref={frameRef} dangerouslySetInnerHTML={{ __html: ebdCode }} /> */}
      <iframe ref={frameRef} src={`https://thd-play.livehyundai.com/video/${params.videoSeq}?noTitle&noControl&noLike`}></iframe>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default PlayerTest;
