import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { Avatar, Stack, Tooltip, Typography, Badge, CardMedia } from "@mui/material";

import { CommonUtils } from "../../utils/common_utils";

import "./ShortProdList.css";

interface propsType {
  prodList: any;
  metadata: any;
  campaignInfo: any;
  postMsg: any;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: "5px",
    top: "7px",
    border: `2px solid ##0fb77e`,
    padding: "0 4px",
    color: "#ffffff",
    backgroundColor: "#0fb77e",
  },
}));

const ShortProdList = (props: propsType) => {
  const cUtils = new CommonUtils();
  const [openProd, setOpenProd] = useState(false);
  const [prodList, setProdList] = useState([] as any);
  const [prodCount, setProdCount] = useState(0);
  const [prodRender, setProdRender] = useState([] as any);
  const [defaultPic, setDefaultPic] = useState("");

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenProd(false);
      setProdRender([] as any);
    };
  }, []);

  // 상품 정보 불러오기
  const LoadProdList = () => {
    // 부모 프레임에 메시지 전달
    // 지금은 모두로 보내지만
    // 나중에 더현대 주소로 변경해야함
    // console.log("SEND LoadProdList key : ", props.metadata.sk);
    postMsg({ msg: "getProdList", video_key: `${props.metadata.sk}` });
  };

  useEffect(() => {
    // console.log("props.prodList : ", props.prodList);
    if (props.prodList !== undefined) {
      let cnt = 0;
      let newProdList: any = [];
      for (const prod_info of props.prodList) {
        if (prod_info.display || prod_info.display === undefined) {
          cnt++;
          newProdList = [...newProdList, prod_info];
        }
      }
      if (cnt !== prodCount) setProdCount(cnt);
      if (newProdList !== prodList) setProdList(newProdList);
      if (defaultPic !== newProdList[0]?.thumbImageUrl) setDefaultPic(newProdList[0]?.thumbImageUrl);
    }
  }, [props.prodList]);

  useEffect(() => {
    setProdRender(
      prodList.map((obj: any, index: number) => {
        return (
          <div key={`prod${index}`} className="thd-prod-item-div">
            <Box
              className="thd-prod-item-box"
              onClick={() => clickProd(obj)} // 품절이어도 상품 클릭은 됨
            >
              <Box className="thd-prod-item-info" sx={{ filter: obj.soldout ? "opacity(0.5)" : "none" }}>
                <Box className="thd-prod-image-box">
                  <img src={obj.thumbImageUrl} alt="" />
                </Box>
                <Box className="thd-prod-desc-box">
                  <div className="thd-prod-desc-title">{obj.name}</div>
                  <div className="thd-prod-desc-price">
                    {obj.soldout ? "품절" : cUtils.numericComma(obj.salePrice)}
                    <span>{"원"}</span>
                  </div>
                </Box>
              </Box>
              {/* <Divider light /> */}
            </Box>
          </div>
        );
      })
    );
  }, [prodList]);

  const handleProdDrawerClose = () => {
    setOpenProd(false);
  };
  const handleProdDrawerOpen = () => {
    setOpenProd(true);
  };

  const handleProdOpen = () => {
    // 품절 상태 확인하기 위해 다시 상품 리스트 호출
    LoadProdList();
    // Drawer 오픈 함수
    handleProdDrawerOpen();
  };

  const clickProd = async (prod: any) => {
    handleProdDrawerClose();
    if (prod.callback !== undefined && prod.callback !== "") {
      setTimeout(() => {
        props.postMsg(prod.callback, "*");
      }, 200);
    }
  };

  // 부모창에 메세지 보내기
  const postMsg = (callback: any) => {
    handleProdDrawerClose();
    if (callback !== undefined && callback !== "") {
      setTimeout(() => {
        props.postMsg(callback, "*");
      }, 200);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          justifyContent: "flex-end",
          p: "17.5px",
          ...(props.prodList !== undefined && props.prodList.length ? { display: "flex" } : { display: "none" }),
        }}
      >
        <Stack direction={"column"} spacing={0.1}>
          <Tooltip title="관련 상품">
            <IconButton
              onClick={() => {
                handleProdOpen();
              }}
              sx={{ p: 0 }}
            >
              <StyledBadge badgeContent={prodCount}>
                <Avatar sx={{ backgroundColor: "#ffffff", width: "50px", height: "50px" }} src={defaultPic}>
                  {""}
                </Avatar>
              </StyledBadge>
            </IconButton>
          </Tooltip>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography sx={{ color: "#ffffff", fontWeight: "500 !important", fontSize: "14px !important" }}>{"관련상품"}</Typography>
          </Box>
        </Stack>
      </Box>
      <Drawer
        PaperProps={{
          elevation: 0,
          style: {
            borderTopRightRadius: "20px",
            borderTopLeftRadius: "20px",
          },
          sx: {
            ...{
              width: "100%",
              // maxWidth: "56.25vh",
              left: "50%",
              transform: "translateX(calc(-50.4% + 0.5px)) !important",
              overflow: "hidden",
            },
          },
        }}
        anchor="bottom"
        open={openProd}
        onClose={() => {
          setOpenProd(false);
        }}
        className="thd-live-more-body"
      >
        <Box className="thd-live-more-body-box">
          <Box className="thd-prod-list-top-header">
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                setOpenProd(false);
              }}
              disableRipple
            >
              <CardMedia component="img" image={"/images/popup_icon_dropdown.png"} sx={{ height: "30px", objectFit: "contain" }} />
              {/* <ExpandMoreIcon sx={{ fontSize: "40px", color: "#BBBBBB" }} /> */}
            </IconButton>
          </Box>
          <Box className="thd-prod-list-header">
            <Box className="thd-prod-list-header-title">
              관련상품 <span>{prodCount}</span>
            </Box>
            {/* 더보기 : campaign_info.callback !== undefined then 더보기 구현, 현재 이 코드는 잘못 되었음. -> 4/2 수정 (현진) */}
            {props.campaignInfo !== undefined && props.campaignInfo.callback !== undefined && props.campaignInfo.callback !== "" ? (
              <Box onClick={() => postMsg(props.campaignInfo.callback)}>
                <Typography sx={{ color: "#878787", fontSize: "14px", cursor: "pointer" }}>{"더보기"}</Typography>
                <Divider light sx={{ borderColor: "#878787" }} />
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Box sx={{ width: "100%" }} className={"thd-prod-scroll"}>
            <nav>
              <div className="thd-prod-list">{prodRender}</div>
            </nav>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default ShortProdList;
